<template>
  <div class='provePayTable'>
    <div class="searchForm">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item style="width: 200px">
          <el-input v-model="queryform.controctNo" placeholder="合同编号" class="input-with-select" clearable> </el-input>
        </el-form-item>
        <el-form-item style="width: 200px">
          <el-input v-model="queryform.orderNo" placeholder="订单号" class="input-with-select" clearable> </el-input>
        </el-form-item>
        <el-form-item style="width: 200px">
          <el-input v-model="queryform.carNumber" placeholder="车牌号" class="input-with-select" clearable> </el-input>
        </el-form-item>
        <el-form-item style="width: 200px">
          <el-input v-model="queryform.customer" placeholder="客户姓名" class="input-with-select" clearable> </el-input>
        </el-form-item>
        <el-form-item v-if="proveType == 2" style="width: 200px">
          <el-select v-model="queryform.writeoffState" clearable placeholder="审核状态">
            <el-option :key="item.value" v-for="item in optionsStatus" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100px" type="primary" size="medium" @click="resetTablelist">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tablebox">
      <el-card>
        <div class="table_header"></div>
        <!-- 核销账单列表数据 -->
        <div class="table-box">
          <el-table :data="tableData" style="width: 100%" border stripe :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              backgroundColor: '#eff3f8',
            }">
            <el-table-column type="index" label="序" width="50" align="center">
              <template slot-scope="scope">
                <span>{{ (pagenum - 1) * size + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orderNo" label="订单号" width="200"> </el-table-column>
            <el-table-column prop="contractNo" label="合同编号" width="200" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="carNumber" label="车牌号" align="center" width="100"> </el-table-column>
            <el-table-column prop="customer" label="客户姓名" width="100" align="center"> </el-table-column>
            <el-table-column prop="companyName" label="公司" width="120" align="center"> </el-table-column>
            <el-table-column prop="periodsTotal" label="期数" width="60" align="center"> </el-table-column>
            <el-table-column prop="repaymentDate" label="还款日" width="110" align="center"> </el-table-column>
            <el-table-column prop="amount" label="还款金额" width="120" align="center"> </el-table-column>
            <el-table-column prop="writeoffRequestDate" label="提交申请时间" min-width="110" align="center"> </el-table-column>
            <el-table-column v-if="proveType == 2" prop="writeoffAuditDate" label="审核时间" width="110" align="center"> </el-table-column>
            <el-table-column v-if="proveType == 2" label="审核状态" width="110" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.writeoffState == 2" type="success">已通过</el-tag>
                <el-tag v-if="scope.row.writeoffState == 3" type="warning">未通过</el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="proveType == 2" prop="writeoffAuditorStr" label="审核操作员" width="110" align="center"> </el-table-column>
            <template v-if="!isOnlyView">
              <el-table-column v-if="proveType == 1 && isHasPress(['rePaymentPlan:writeoffPass','rePaymentPlan:writeoffUnPass'])" label="操作" :width="180" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button v-has-press="['rePaymentPlan:writeoffPass','rePaymentPlan:writeoffUnPass']" size="mini" type="primary" icon="el-icon-wallet" @click="verificationPay(scope.row.orderNo)">审
                    核</el-button>
                </template>
              </el-table-column>
              <el-table-column v-if="proveType == 2 && isHasPress(['rePaymentPlan:revokeWriteoff'])" label="操作" :width="180" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button v-has-press="['rePaymentPlan:revokeWriteoff']" :disabled="scope.row.writeoffState !== 2" size="mini" type="info" icon="el-icon-wallet"
                    @click="revokePay(scope.row.orderNo)">撤 销</el-button>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>
    
<script>
import { getRequestedRepaymentPlan, getAuditedRepaymentPlan, writeoffPass, writeoffUnPass, revokeWriteoff } from "@/api/provePayment.js";
import { mapGetters } from "vuex";
export default {
  name: "",
  props: {
    proveType: {
      type: String,
      default: "",
    },
    adminAuthority: {
      type: Boolean,
    },
    isAuditor: {
      type: Boolean,
      default: false, // hasCwAdmin财务人员
    },
  },
  data() {
    return {
      queryform: {
        controctNo: "", //合同编号
        orderNo: "", //订单号
        carNumber: "", //车牌号
        customer: "", //客户姓名
        writeoffState: "", //核销审核状态
      },
      tableData: [], // 审核合同列表
      pagenum: 1, // 页码
      size: 10, // 单页数据数
      total: 10, // 数据总数
      optionsStatus: [
        { value: 2, label: "已通过" },
        { value: 3, label: "未通过" },
      ],
    };
  },
  mounted() {
    console.log("刷新表格数据", this.proveType);
    this.getTableList();
  },
  computed: {
    ...mapGetters(["isHasPress"]),
    isOnlyView() {
      return this.$store.getters.getOnlyViewRole;
    },
  },
  methods: {
    onSubmit() {
      console.log("search", this.queryform);
      this.getTableList();
    },

    getTableList() {
      let obj = { ...this.queryform };

      console.log(this.queryform, obj);
      const getData = this.proveType == 1 ? getRequestedRepaymentPlan : getAuditedRepaymentPlan;
      getData(obj, this.pagenum, this.size).then((res) => {
        const DataList = res.data.dataList;
        DataList.forEach((ele) => {
          ele.signTimestamp = this.$moment(ele.signDate).valueOf();
          const amount = ele.amount;
          ele.amount = amount != null ? _.toNumber(amount).toLocaleString() : "";

          const repaymentDate = ele.repaymentDate;
          ele.repaymentDate = this.$moment(repaymentDate).format("YYYY-MM-DD");

          const writeoffRequestDate = ele.writeoffRequestDate;
          ele.writeoffRequestDate = this.$moment(writeoffRequestDate).format("YYYY-MM-DD HH:mm:ss");

          const writeoffAuditDate = ele.writeoffAuditDate;
          ele.writeoffAuditDate = this.$moment(writeoffAuditDate).format("YYYY-MM-DD HH:mm:ss");
        });
        this.tableData = DataList;
        this.total = res.data.total;
      });
    },
    // 重置当前页码 刷新合同列表
    resetTablelist() {
      this.pagenum = 1;
      this.size = 10;
      this.onSubmit();
    },
    // 监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.size = newSize;
      this.pagenum = 1;
      this.onSubmit(); // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.onSubmit(); // 发起数据请求
    },
    submitWriteoff(id) {
      console.log(id);
      return writeoffPass(id).then((res) => {});
    },
    submitUnWriteoff(id) {
      return writeoffUnPass(id).then((res) => {});
    },
    // 审核核销账单
    verificationPay(id) {
      this.$confirm("是否通过该核销账单?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "通过",
        cancelButtonText: "不通过",
        type: "warning",
      })
        .then(async () => {
          await this.submitWriteoff(id);
          this.resetTablelist();
          this.$message.success("通过核销成功");
        })
        .catch(async (err) => {
          if (err == "cancel") {
            await this.submitUnWriteoff(id);
            this.resetTablelist();
            this.$message.success("不通过核销成功");
          }
        });
    },
    // 撤销-核销
    revokePay(id) {
      this.$confirm("是否撤销该已通过的核销账单?", "提示", {
        distinguishCancelAndClose: false,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        revokeWriteoff(id).then((res) => {
          this.resetTablelist();
          this.$message.success("撤销成功");
        });
      });
    },
  },
};
</script>
    
<style lang='less' scoped>
</style>